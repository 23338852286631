import React, { Component } from 'react';

class Portfolio extends Component {
  render() {

    if(this.props.data)
    {
      var projects = this.props.data.projects.map(function(project){
        return <div key={project.url}><h3>{project.title}</h3>
            <p className="info"><a href={project.url} target="_blank" rel="noopener noreferrer">Youtube</a><span>&bull;</span> <em className="date">{project.name}</em></p>
            <p>{project.category}</p>
        </div>
      })
    }

    return (
      <section id="portfolio">

      <div className="row work">

      <div className="three columns header-col">
        <h1><span>Conferences</span></h1>
      </div>

      <div className="nine columns main-col">
      {projects}
      </div>
      </div>
      </section>
    );
  }
}

export default Portfolio;
